import React, { useState, useEffect } from 'react';
import dance from './images/dance.jpg';
import marathon from './images/marathon.jpg';
import magic from './images/magic.png';
import quadball from './images/quadball.jpeg';
// import chef from './images/chef.jpeg';
import stem from './images/stem.jpeg';
import app from './images/app.jpeg';
import siblings from './images/siblings.jpeg';
import hackathon from './images/hackathon.jpeg';
import outdoor from './images/outdoor.jpeg';
import beach from './images/beach.jpeg';
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSprings, animated } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import ProjectGallery from './ProjectGallery';
import ChatbotWidget from './ChatbotWidget';

const phrases = [
  { phrase: 'I like warm weather and beaches and whatnot', image: beach },
  { phrase: "This is a photo of me dancing", image: dance },
  // { phrase: 'Sometimes I cook', image: chef },
  { phrase: "I've run a marathon once", image: marathon },
  { phrase: 'I teach kids engineering and coding', image: stem },
  { phrase: "I'm a  magician", image: magic },
  { phrase: "I play quadball", image: quadball },
  { phrase: "I enjoy the outdoors. This is Mount Rainier!", image: outdoor },
  { phrase: "I'm a hackathon organizer", image: hackathon },
  { phrase: 'I like to compete in hackathons', image: app },
  { phrase: "I'm the second oldest child", image: siblings },
];

function Home() {
  const [typingText, setTypingText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [cards, setCards] = useState(phrases);

  // Track whether a card drag was successful
  const [hasDragged, setHasDragged] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  // Show the hint only if user hasn't dragged yet
  useEffect(() => {
    if (!hasDragged) {
      const hintTimerIn = setTimeout(() => {
        setShowHint(true);
      }, 2000); // show after 2s

      // Optional: auto-hide after 8s
      const hintTimerOut = setTimeout(() => {
        setShowHint(false);
      }, 8000);

      return () => {
        clearTimeout(hintTimerIn);
        clearTimeout(hintTimerOut);
      };
    }
  }, [hasDragged]);

  const DRAG_THRESHOLD = 150; // Distance required to change card position

  const [springs, api] = useSprings(cards.length, (index) => ({
    x: -7 * (cards.length - index - 1),
    y: 0,
    scale: index === cards.length - 1 ? 1 : 0.97 ** (cards.length - index - 1),
    rot: index === cards.length - 1 ? 0 : -4 * (cards.length - index - 1),
    from: { x: 0, rot: 0, scale: 1, y: -100 },
  }));

  const bind = useGesture(
    {
      onDrag: ({ args: [index], down, movement: [mx], distance }) => {
        api.start((i) => {
          if (index !== i) {
            return {
              rot: -4 * (cards.length - i - 1),
              scale: 0.97 ** (cards.length - i - 1),
              x: -7 * (cards.length - i - 1),
            };
          }
          const x = down ? mx : 0;
          const rot = mx / 100;
          const scale = down ? 0.997 ** Math.abs(mx) : 1;
          return { x, rot, scale, immediate: down };
        });
      },
      onDragEnd: ({ args: [index], movement: [mx], distance }) => {
        if (distance[0] < DRAG_THRESHOLD) {
          // Snap back if below threshold
          api.start((i) => {
            if (i !== index) return;
            return {
              x: -7 * (cards.length - index - 1),
              y: 0,
              scale: 0.97 ** (cards.length - index - 1),
              rot: -4 * (cards.length - index - 1),
              immediate: false,
            };
          });
          return;
        }

        // This is a successful drag -> remove hint & never show again
        setHasDragged(true);
        setShowHint(false);

        // Move card off-screen
        api.start((i) => {
          if (index !== i) return;
          return { y: 1000, rot: 0, scale: 1 };
        });

        // Re-stack cards
        setTimeout(() => {
          setCards((prev) => {
            let newCards = [...prev];
            const [swipedCard] = newCards.splice(index, 1);
            newCards = [swipedCard, ...newCards];
            return newCards;
          });

          // Reset positions
          api.start((i) => {
            if (index === i) {
              return { y: 0, immediate: true };
            }
            return {
              y: 0,
              rot: -4 * (cards.length - i - 1),
              scale: 0.97 ** (cards.length - i - 1),
              x: -7 * (cards.length - i - 1),
            };
          });
        }, 300);
      },
    },
    {
      drag: {
        filterTaps: true,
      },
    }
  );

  // Typing effect for the last card's phrase
  useEffect(() => {
    let currentText = '';
    let index = 0;
    setIsTyping(true);
    const phrase = cards[cards.length - 1].phrase;

    const typingInterval = setInterval(() => {
      if (index < phrase.length) {
        currentText += phrase[index];
        setTypingText(currentText);
        index++;
      } else {
        clearInterval(typingInterval);
        setIsTyping(false);
      }
    }, 50);

    return () => clearInterval(typingInterval);
  }, [cards]);

  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <header className="App-header">
        {/* Dark/Light Mode Toggle */}
        <button onClick={toggleTheme} className="theme-toggle">
          {isDarkMode ? (
            <FontAwesomeIcon icon={faSun} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faMoon} size="2x" />
          )}
        </button>

        {/* Card Stack Container */}
        <div className="card-container">
          {springs.map(({ x, y, rot, scale }, i) => (
            <animated.div
              key={i}
              style={{
                x,
                y,
                rotateZ: rot,
                scale,
                position: 'absolute',
                width: '100%',
                height: '100%',
                willChange: 'transform',
                touchAction: 'none',
              }}
              {...bind(i)}
            >
              <animated.div
                style={{
                  backgroundImage: `url(${cards[i].image})`,
                  height: '100%',
                }}
                className="card"
              />
            </animated.div>
          ))}

          {/* Only show hint if user has NOT dragged a card yet */}
          {showHint && !hasDragged && (
            <div className="hint-bubble">
              <p>Try dragging the cards to see my other pictures!</p>
            </div>
          )}
        </div>

        <h1>Hello 👋! I'm Yuen Ler.</h1>

        {/* Typing text */}
        <div style={{ marginBottom: 10 }}>
          <span className="typing-text">{typingText}</span>
          <span className={`cursor ${isTyping ? '' : 'blink'}`}>|</span>
        </div>

        {/* View Projects Button */}
        <button
          type="button"
          className="fancy-button"
          onClick={() => setShowModal(true)}
        >
          View My Personal Projects 👀
        </button>
      </header>

      {/* Modal for Projects */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        className="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ProjectGallery />
        </Modal.Body>
        <Modal.Footer>
          <div className="social-icons">
            <a
              href="https://www.linkedin.com/in/yuenler"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link linkedin"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a
              href="mailto:yuenlerchow@college.harvard.edu"
              className="social-link email"
            >
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
            </a>
            <a
              href="https://github.com/yuenler"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link github"
            >
              <FontAwesomeIcon icon={faGithub} size="2x" />
            </a>
          </div>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ChatbotWidget />
    </div>
  );
}

export default Home;
